import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import ArrowIcon from 'components/atoms/ArrowIcon';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ResourceGrid from 'components/molecules/ResourceGrid';
import ResourceTypeTag from 'components/molecules/ResourceTypeTag';
import CtaSection from 'components/organisms/CtaSection';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';

const DownloadsContent = ({ hero, newsletterCta, cta, resources = [] }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <div className="u-container pt-32 md:pt-48 mb-12 md:mb-20 px-6 lg:px-md xl:px-0">
        <Link
          className="inline-flex items-center text-yellow font-semibold text-lg tracking-tighter transition hover:text-dark-grey focus:text-dark-grey"
          to="/resources"
        >
          <ArrowIcon className="transform rotate-180 mr-4 -mt-px" /> Back to
          Resources
        </Link>
        <h1 className="u-h1 mt-6 md:mt-8 mb-4">{hero?.title}</h1>
        <div className="text-xl leading-8 w-120 max-w-full mb-12 md:mb-20">
          {hero?.description}
        </div>

        <div className="space-y-16 lg:space-y-60">
          {resources
            .slice(0, 4)
            .map(({ thumbnailUrl, title, excerpt, url }, index) => (
              <div
                key={index}
                className={`flex flex-col ${
                  index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
                } items-center`}
              >
                <div
                  className={`w-full lg:w-1/2 flex items-center justify-center bg-gray-100 md:bg-transparent mb-8 lg:mb-0 py-8 md:py-0  ${
                    index % 2 === 0 ? 'lg:pr-12' : 'lg:pl-12'
                  }`}
                >
                  <img
                    src={thumbnailUrl}
                    alt={title}
                    className="rounded-r-5xl w-32 md:w-89 lg:w-110 h-auto"
                  />
                </div>
                <div
                  className={`w-full lg:w-1/2 ${
                    index % 2 === 0 ? 'lg:pl-12' : 'lg:pr-12 lg:pl-12'
                  }`}
                >
                  <ResourceTypeTag type="download" />
                  <h2 className="u-h2 mb-3.5">{title}</h2>
                  <p className="mb-4 md:mb-8 text-lg md:text-xl leading-snug tracking-tighter lg:pr-12">
                    {excerpt}
                  </p>
                  <Link className="u-btn u-btn--dark" to={url}>
                    Learn more
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>

      <NewsletterCtaSection
        headline={newsletterCta?.headline}
        background="bg-yellow text-white"
      />

      {resources.length > 4 && (
        <ResourceGrid
          cols={3}
          noTag
          resources={resources.slice(4, showAll ? undefined : 13)}
        />
      )}

      {resources.length > 13 && !showAll && (
        <div className="pt-20 pb-36 text-center">
          <button
            className="bg-dark-grey text-white py-5 px-10 text-lg tracking-tighter leading-none hover:opacity-75 focus:opacity-75 transition rounded-full font-semibold"
            onClick={() => setShowAll(true)}
          >
            Load more
          </button>
        </div>
      )}

      <CtaSection headline={cta?.headline} />
    </>
  );
};

const RESOURCES_FIXTURE = [
  {
    title: 'From the Cold Vault: 2020 Juice Trends',
    thumbnailUrl:
      '/uploads/graphic_report-image_Juice-Trends_light-green-wide.png',
    url: '/downloads',
    type: 'download',
    excerpt:
      'Energy Drink Performance: Crystal clear insights into the mysterious world of independent convenience stores',
  },
];

export const DownloadsPreview = ({ entry }) => {
  return (
    <DownloadsContent
      {...entry.get('data').toJS()}
      resources={RESOURCES_FIXTURE}
    />
  );
};

const DownloadsPage = ({
  data: {
    markdownRemark: { frontmatter },
    downloads,
  },
}) => {
  const { seo } = frontmatter;

  const resources = downloads.edges.map(({ node }) => {
    const { title, date, heroImage, slug, description } =
      node.frontmatter || {};

    return {
      title,
      thumbnailUrl: heroImage,
      url: `/downloads/${slug}`,
      type: 'download',
      excerpt: description,
    };
  });

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <DownloadsContent {...frontmatter} resources={resources} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query DownloadsQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/download/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
        }
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    downloads: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "download" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heroImage
            description
          }
        }
      }
    }
  }
`;

export default DownloadsPage;
